<template>
  <div class="app-content">
    <div class="p-main">
      <div class="catalog">
        <div class="catalog-list" v-if="list.length" ref="scrollRef">
          <van-list finished-text="" error-text="- 请求失败，点击重新加载 -"
                    :immediate-check="true" :error.sync="errorFlag" :finished="finished" @load="getMoreList">
            <div class="catalog-item" v-for="(item, index) in list" :key="index" @click="toList(item)">
              <img class="catalog-item-img" :src="item.catalogPicPath ? item.catalogPicPath : defaultImg"/>
              <div class="catalog-item-name text-ellipsis">
                {{ item.catalogName }}
              </div>
              <div class="catalog-item-page">
                文档：{{ item.count }}篇
              </div>
            </div>
          </van-list>
          <div v-if="list.length === total && (total > queryParams.pageSize)" class="no-more">
            - 已没有更多安全法规目录 -
          </div>
        </div>
        <div class="catalog-list" v-else>
          <van-empty description="暂无安全法规目录"></van-empty>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import service from '@/api/service'

export default {
  name: "standard-index",
  data () {
    return {
      queryParams: {
        searchKey: '',
        pageSize: 16,
        pageNum: 1
      },
      list: [],
      total: 0,
      finished: false,
      errorFlag: false,
      listLoading: false,
      defaultImg: require('@/assets/images/catalog-default.png')
    }
  },
  mounted() {
    this.finished = false
    this.queryParams.pageNum = 1
    this.list = []
    this.getList()
  },
  methods: {
    searchList () {
      this.finished = false
      this.queryParams.pageNum = 1
      if (this.$refs.scrollRef) this.$refs.scrollRef.scrollTop= 0
      this.getList()
    },
    getMoreList () {
      if (this.list.length < this.total) {
        this.finished = false
        this.queryParams.pageNum += 1
        this.getList()
        return
      }
      this.finished = true
    },
    getList () {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      service.fetchStandardCatalogList({
        pageSize: this.queryParams.pageSize,
        pageNum: this.queryParams.pageNum,
        title: this.queryParams.searchKey,
        publicStatus: "1"
      })
          .then(res => {
            this.total = res.data.total
            this.list = this.queryParams.pageNum === 1 ? res.data.records : this.list.concat(res.data.records)
            this.errorFlag = false
            this.$toast.clear()
          })
          .catch(() => {
            this.errorFlag = true
            this.$toast.clear()
          })
    },
    toList (item) {
      this.$router.push({
        path: '/standard',
        query: {
          id: item.id,
          title: item.catalogName
        }
      })
    }
  }
}
</script>

<style scoped>
.catalog {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 0 10px;
  box-sizing: border-box;
}
.catalog-list {
  width: calc(100vw - 20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: calc(100vh - 15px);
  overflow-y: auto;
  overflow-x: hidden;
}
.catalog-item {
  float: left;
  width: calc(50vw - 15px);
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #e9e9e9;
}
.catalog-item-img {
  float: left;
  width: calc(50vw - 17px);
  height: 110px;
  border-radius: 5px 5px 0 0;
}
.catalog-item-name {
  float: left;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  height: 30px;
  background: #ffffff;
  color: #666;
  font-size: 14px;
}
.catalog-item-page {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 8px 10px 8px;
  border-radius: 0 0 5px 5px;
  color: #999999;
  background: #ffffff;
}
.catalog-item:nth-child(odd) {
  margin-right: 5px;
}
.catalog-item:nth-child(even) {
  margin-left: 5px;
}

</style>
