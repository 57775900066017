import axios from "axios";
import { Toast } from 'vant';

Toast.allowMultiple();
Toast.setDefaultOptions({ duration: 1000 });

const http = axios.create({
    baseURL : '/prod-api',
    timeout : 600000,
    withCredentials: true
})

http.interceptors.request.use(
    config => {
        if (localStorage.getItem('mobileToken')) {
            config.headers['x-token'] = localStorage.getItem('mobileToken');
        }
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

const commonRequest = (request) => {
    return request
        .then(response => {
            if(response.data.code !== 200){
                Toast(response.data.msg)
                throw new Error(response.data.msg)
            }
            return response.data
        })
        .catch(error => {
            if (error.response) {
                let message = error.response.data.message ? error.response.data.message : '服务异常'
                switch (error.response.status) {
                    case 304:
                        Toast(message)
                        throw new Error(message)

                    case 404:
                        Toast(message)
                        throw new Error(message)

                    case 401:
                        Toast('登录状态已超时，请重新登录!')
                        throw new Error('登录状态已超时，请重新登录!')

                    case 413:
                        Toast('上传文件过大')
                        throw new Error('上传文件过大')

                    case 500:
                        Toast(message)
                        throw new Error(message)
                }
            } else if (error.message.indexOf('timeout') !== -1) {
                Toast('网络请求超时')
                // throw new Error('网络请求超时')
            }else {
                Toast(error.message)
                // throw new Error(error.message)
            }
        })
}

const tansParams = params => {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}
const service = {
    // 上报
    addReportInfo(data) {
        return commonRequest(http.post('/mobile/reportInfo', data))
    },
    // 上传
    addReportFile(data) {
        return commonRequest(http.post('/mobile/reportInfo/upload', data))
    },
    // 安全法规目录列表
    fetchStandardCatalogList (data) {
        return commonRequest(http.get(`/mobile/catalog/list?${tansParams(data)}`))
    },
    // 安全法规列表
    fetchStandardList (data) {
        return commonRequest(http.get(`/mobile/standard?${tansParams(data)}`))
    },
    // 安全法规详情
    fetchStandardInfo (id) {
        return commonRequest(http.get(`/mobile/standard/${id}`))
    }
}

export default service
