<template>
  <div class="app-content">
    <div class="p-main">
      <div class="report">
        <div class="p-content">
          <div class="p-content-title">
            <div class="p-content-title-block"></div>
            <div class="p-content-title-text">
              隐患地点
              <span class="color-red">*</span>
            </div>
          </div>
          <div class="p-content-main">
            <textarea class="report-textarea" style="height: 50px" v-model="form.location" placeholder="请输入隐患地点，不超过50字"></textarea>
          </div>
        </div>
        <div class="p-content">
          <div class="p-content-title">
            <div class="p-content-title-block"></div>
            <div class="p-content-title-text">
              现场图片
            </div>
          </div>
          <div class="p-content-main">
            <div class="upload-list">
              <div class="upload-item" v-for="(item, index) in fileList" :key="index">
                <img :src="item" alt="" class="upload-item-image" @click="previewImg">
                <div class="upload-item-del" @click="deleteImg(index)">
                  <img src="@/assets/images/reduce-icon.png" alt="" style="width: 20px; height: 20px;">
                </div>
              </div>
              <van-uploader
                  v-if="fileList.length < 6"
                  :preview-image="false"
                  :after-read="afterCard"
              >
                <div class="upload-item-icon">
                  <img src="@/assets/images/add-icon.png" alt="" style="width: 40px; height: 40px;">
                </div>
                <div class="upload-item-text">
                  选择图
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="p-content">
          <div class="p-content-title">
            <div class="p-content-title-block"></div>
            <div class="p-content-title-text">
              隐患描述
              <span class="color-red">*</span>
            </div>
          </div>
          <div class="p-content-main">
            <textarea class="report-textarea" v-model="form.description" placeholder="请输入隐患描述，不超过200字"></textarea>
          </div>
        </div>
        <div class="p-content">
          <div class="p-content-title">
            <div class="p-content-title-block"></div>
            <div class="p-content-title-text">
              上报人联系方式（选填，用于接收反馈）
            </div>
          </div>
          <div class="p-content-main">
            <input class="report-input" v-model="form.reportPerson" placeholder="请输入上报人及联系方式" />
          </div>
        </div>
        <div class="upload-btn" @click="submitFn">
          现在上报
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "@/api/service";
import { ImagePreview, Dialog } from 'vant';
import * as ImageCompressor from 'image-conversion';

export default {
  name: "report-view",
  data () {
    return {
      form: {
        location: "",
        description: "",
        reportPerson: ""
      },
      fileList: [],
      upload: {
        url: "",
        stateNum: 0,
      }

    }
  },
  methods: {
    previewImg () {
      ImagePreview(this.fileList);
    },
    deleteImg (index) {
      Dialog.confirm({
        title: '提示',
        message: '确认删除当前图片吗',
      })
          .then(() => {
            this.fileList.splice(index, 1)
          })
          .catch(() => {
            // on cancel
          });
    },
    async afterCard(file){
      ImageCompressor.compressAccurately(file.file, file.file.size/1024/10).then(res => {
        let newFile = new File([res], file.name, { type: res.type, lastModified: Date.now() })
        const formData = new FormData();
        formData.append('file', newFile);
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        service.addReportFile(formData)
            .then(res => {
              this.fileList.push(res.data.url);
              this.$toast.clear()
            })
            .catch(() => this.$toast.clear())
      })

    },
    submitFn () {
      if (!this.form.location) {
        this.$toast('隐患地点不能为空！');
        return
      }
      if (this.form.location.length > 50) {
        this.$toast('隐患地点不能超过50字！');
        return
      }
      if (!this.form.description) {
        this.$toast('隐患描述不能为空！');
        return
      }
      if (this.form.description.length > 200) {
        this.$toast('隐患描述不能超过200字！');
        return
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      service.addReportInfo({
        sitePic: this.fileList.join(','),
        ...this.form,
        disposeStatus: "01"
      })
          .then(() => {
            this.$toast.clear()
            this.$toast.success({
              message: '上报成功',
              forbidClick: true,
            })
            for(let key in this.form) {
              this.form[key] = ''
            }
            this.fileList = []
          })
          .catch(() => {
            this.$toast.clear()
            this.$toast.fail({
              message: '上报失败',
              forbidClick: true,
            })
          })
    }
  }
}
</script>

<style scoped>
.report {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
.report-textarea {
  border: 1px solid #EBEAEA;
  background: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  height: 90px;
}
.report-input {
  border: 1px solid #EBEAEA;
  background: none;
  padding: 12px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.upload-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.upload-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(33% - 10px);
  margin: 0 15px 15px 0;
  height: 110px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}
.upload-item-image {
  width: 100%;
  height: 110px;
  border-radius: 10px;
}
.upload-item-del {
  position: absolute;
  right: 6px;
  top: 6px;
}
.upload-item-text {
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.upload-item-icon {
  display: flex;
  justify-content: center;
}
.upload-item:nth-child(3n+3) {
  margin-right: 0;
}
.upload-btn {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 50px;
  background: #35A87D;
  border-radius: 30px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 20px 15%;
}
</style>
