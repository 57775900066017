<template>
  <div class="app-content">
    <div class="p-main">
      <div class="standard-info">
        <div class="info-title" id="info-title">
          <van-icon name="arrow-left" size="32" @click="toBack" />
          <div class="info-title-text">
            {{ title }}
          </div>
        </div>
        <iframe
            width="100%"
            :height="`${iframeHeight}px`"
            :src="`https://fzs.yuntsl.com/pdf/web/viewer.html?file=${url}`"></iframe>
      </div>

    </div>
  </div>
</template>

<script>
import service from "@/api/service";
export default {
  name: "standard-info",
  data () {
    return {
      id: "",
      url: '',
      iframeHeight: 0,
      title: '',
      catalogId: ""
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.catalogId = this.$route.query.catalogId
    this.catalogName = this.$route.query.title
    this.iframeHeight =  document.body.clientHeight - document.getElementById("info-title").offsetHeight
    if (this.id) this.getInfo()

  },
  methods: {
    getInfo () {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      service.fetchStandardInfo(this.id)
          .then(res => {
            this.title = res.data.title
            this.url = res.data.fileUrl
            console.log(`https://fzs.yuntsl.com/pdf/web/viewer.html?file=${this.url}`)
            this.$toast.clear()
          })
          .catch(() => this.$toast.clear())
    },
    toBack () {
      this.$router.push({
        path: '/standard',
        query: {
          id: this.catalogId,
          title: this.catalogName
        }
      })
    }
  }
}
</script>

<style scoped>
.standard-info {
  display: flex;
  flex-direction: column;
}
.info-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  color: #35A87D;
  font-size: 18px;
  word-break: break-all;
  font-weight: bold;
}
.info-title-text {
  flex: 1;
  text-align: left;
  padding-left: 10px;
}
</style>
