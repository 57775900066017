<template>
  <div class="app-content">
    <div class="p-main">
        <div class="standard">
          <div class="info-title" id="catalog-title">
            <van-icon name="arrow-left" size="32" @click="toBack" />
            <div class="info-title-text">
              {{ title }}
            </div>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div class="standard-search">
              <div class="search">
                <input placeholder="请输入搜索内容" v-model="queryParams.searchKey" @keyup.enter="searchList" class="search-input" />
                <div class="search-btn" @click="searchList">搜索</div>
              </div>
            </div>
          </div>
          <div class="standard-list" :style="{height: `${iframeHeight}px`}" v-if="list.length" ref="scrollRef">
            <van-list finished-text="" error-text="- 请求失败，点击重新加载 -" :immediate-check="false" :error.sync="errorFlag" :finished="finished" @load="getMoreList">
              <div class="standard-item" v-for="(item, index) in list" :key="index" @click="toInfo(item)">
                <div class="standard-item-title">
                  {{ item.title }}
                </div>
                <div class="standard-item-time">
                  发布时间：{{ item.createTime ? item.createTime.substring(0, 10) : '-' }}
                </div>
              </div>
            </van-list>
            <div v-if="list.length === total && (total > queryParams.pageSize)" class="no-more">
              - 已没有更多安全法规 -
            </div>
          </div>
          <div class="standard-list" v-else>
            <van-empty description="暂无安全法规"></van-empty>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import service from '@/api/service'

export default {
  name: "standard-index",
  data () {
    return {
      queryParams: {
        searchKey: '',
        pageSize: 20,
        pageNum: 1
      },
      list: [],
      total: 0,
      finished: false,
      errorFlag: false,
      listLoading: false,
      catalogId: "",
      defaultImg: require('@/assets/images/catalog-default.png'),
      title: '',
      iframeHeight: 0
    }
  },
  mounted() {
    this.finished = false
    this.queryParams.pageNum = 1
    this.list = []
    this.catalogId = this.$route.query.id
    this.getList()
    this.title = this.$route.query.title
    console.log(document.getElementById("catalog-title").offsetHeight)
    this.$nextTick(() => this.iframeHeight =  document.body.clientHeight - document.getElementById("catalog-title").offsetHeight - 60 - 10)
  },
  methods: {
    searchList () {
      this.finished = false
      this.queryParams.pageNum = 1
      if (this.$refs.scrollRef) this.$refs.scrollRef.scrollTop= 0
      this.getList()
    },
    getMoreList () {
      console.log(this.list.length)
      console.log(this.total)
      console.log(this.list.length < this.total)
      if (this.list.length < this.total) {
        this.finished = false
        this.queryParams.pageNum += 1
        this.getList()
        return
      }
      this.finished = true
    },
    getList () {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      service.fetchStandardList({
        pageSize: this.queryParams.pageSize,
        pageNum: this.queryParams.pageNum,
        title: this.queryParams.searchKey,
        publicStatus: "1",
        catalogId: this.catalogId
      })
        .then(res => {
          this.total = res.data.total
          this.list = this.queryParams.pageNum === 1 ? res.data.records : this.list.concat(res.data.records)
          this.errorFlag = false
          this.$toast.clear()
        })
        .catch(() => {
          this.errorFlag = true
          this.$toast.clear()
        })
    },
    toBack () {
      this.$router.push({
        path: '/standard-catalog'
      })
    },
    toInfo (item) {
      this.$router.push({
        path: '/standard-info',
        query: {
          id: item.id,
          catalogId: this.catalogId,
          title: this.title
        }
      })
    }
  }
}
</script>

<style scoped>
.standard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.info-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  color: #35A87D;
  font-size: 18px;
  word-break: break-all;
  font-weight: bold;
  border-bottom: 1px solid #d0d0d0;
}
.info-title-text {
  flex: 1;
  text-align: left;
  padding-left: 10px;
}
.standard-search {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 8px;
  margin: 10px;
  flex: 1;
}
.search {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}
.search input:focus {
  outline: none;
}
.search-input {
  flex: 1;
  background: none;
  border: none;
  border-radius: 8px 0 0 8px;
  height: 40px;
  font-size: 14px;
  padding: 0 15px;
  box-sizing: border-box;
}
.search-btn {
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 0 8px 8px 0;
  font-size: 15px;
  color: #35A87D;
  border-left: 1px solid #35A87D;
}
.standard-list {
  width: calc(100vw - 24px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 12px;
}
.standard-item {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.standard-item:nth-child(3n + 3) {
  margin-right: 0;
}
.standard-item-title {
  font-size: 18px;
  color: #35A87D;
  margin-bottom: 5px;
}
.standard-item-time {
  font-size: 14px;
  color: #444;
}
</style>
