import Vue from 'vue'
import Router from 'vue-router'
import ReportView from "@/views/report";
import StandardView from "@/views/standard";
import StandardInfoView from "@/views/standard/info";
import StandardCatalogView from "@/views/standard/catalog";

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: ReportView,
    meta: {
      title: "安全随手报"
    }
  },
  {
    path: '/standard-catalog',
    component: StandardCatalogView,
    meta: {
      title: "安全法规"
    }
  },
  {
    path: '/standard',
    component: StandardView,
    meta: {
      title: "安全法规"
    }
  },
  {
    path: '/standard-info',
    component: StandardInfoView,
    meta: {
      title: "安全法规"
    }
  }
]


const router = new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    console.log(to.meta.title)
    document.title = to.meta.title;
  }
  next();
});

export default router
